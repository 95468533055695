* {
  border: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  // font-family: 'Courgette', cursive;
  font-family: 'Zen Dots', cursive;
}

a {
  text-decoration: none;
}

.my_name {
  color: #e6ffff;
}

.first_indent {
  margin-left: 2.6vw; /* 50px */
}

.second_indent {
  margin-left: 5.2vw; /* 100px */
}

.third_indent {
  margin-left: 7.8vw; /* 150px */
}

.triangle {
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-bottom-width: 21vh; /* 227px */
  border-top-width: 45vh; /* 480px */
  border-left: 75vw solid #2c353180; /* 1500px */
  z-index: -5;
  position: absolute; 
  left: 10%;
  top: 15%; 
  // margin: auto;
  // top: 50%;
  // right: 50%;
}

html {
  // border: 2px solid black; 
  height: 100vh; /* 1000px */
  width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.9);/*#2C3531*/;/*rgb(46, 45, 45);*/
  // background-image: url("portfolio");
  min-width: 1000px;
}

body {
  // border: 1px solid red; 
  height: 100vh; /* 1000px */
  width: 100vw;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  // align-items: center;
  box-shadow: 0px 0px 40px 10px #04949470 inset;
  // overflow: auto;
}

.introduction_screen_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* around */ 
  // border: 1px solid green;
  align-items: center; /* very important otherwise would stretch */
  width: 100vw;
  height: 100vh;
}

.nav_wrapper {
  // border: 3px solid #d9b08c; 
  // box-shadow: 2px 2px 15px #049494;
  text-align: center;
  margin-left: 2px; 
  margin-right: 1.3vw; /* 25px */
  margin-bottom:1px; 
  margin-top: 0;
  // position: absolute; 
  // top: 0; 
  // right: 0; 
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; 
  width: 12.3vw; /* 210px */
  // max-width: 210px; 
  min-width: 12.3vw; /* 210 px */
  min-height: 100vh;
  height: 100vh; 
}

.nav_wrapper h4 {
  color: #0ef8f8;
  font-size: 1vw; /* 18px */
  line-height: 0.90vw; /* 0.63 */
}

.nav_wrapper span {
  font-size: 0.8vw; /* 16px */
}

.nav_wrapper ul {
  // border: 1px solid blue; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  width: 100%;
  height: 30%; 
}

.nav_wrapper a {
  width: 95%;
  text-decoration: none;
  color: #66FBFB;
}

.nav_wrapper a:hover {
  color: #039595;
}

.nav_wrapper ul li {
  border: 4px solid #049494;
  box-shadow: 2px 2px 15px #049494; 
  box-shadow: 2px 2px 15px #049494; 
  width: 100%;
  height: 88%; /* 50 px */
  text-align: center;
  font-size: 1.25vw; /* 25 px */
  line-height: 2.6vw; /* 50px */
}

.nav_load {
  // border: 1px solid red;
  // width: 7vw; /* no need already responsive */
  // height: 4.5vw;
}

// writing

.introduction {
  // border: 2px solid orange;
  // font-size: 35px; /* 35px */ 
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  // height: 50%;
  width: 72.5%; /* 70% */
  // align-self: center;
  margin-left: 2%;
  // justify-content: center;

  // margin: 0 auto;
  // width: 1550px; 
  // height: 600px; 
  // margin: 300px auto 0 auto;
  // margin: 12.5% 12.5%;

}

.topic_title {
  // border: 1px solid red;
  font-size: 3.5vw;/*4.5rem; *//* 75 px */
  color: #0ef8f8;
  text-shadow: 1px 1px 15px #0ef8f875;
}

.topic_title_name {
  // border:1px solid red;
  font-size: 3.5vw;/*4.5rem; *//* 75 px */
  color: #0ef8f8;
  text-shadow: 1px 1px 15px #0ef8f875;
  /* */
  overflow: hidden;
  white-space: nowrap;
  animation: type 5s;
}

@keyframes type {
  from { width: 0 }
  to {width: 100% }
}

.second_topic_title {
  font-size: 1.85vw; /* 40px */ /* 2vw */
  color: #0ef8f8;
  text-shadow: 1px 1px 15px #0ef8f875;
}

.tag {
  font-size: 1.20vw; /* 24px */ /*1.5 rem*/ /* 1.32vw */
  font-weight: bold;
  color: #116466;
}

.para {
  font-size: 0.755vw; /*20px */ /* 0.75 */
  color: #e6ffff;
  // text-shadow: 0.5px 0.5px 1px #e6ffff;
  // text-shadow: 0px 0px 7px #e6ffff;
}

.wall {
  width: 6.5vw; /* 100px */
  height: 0.5vw; /* 10 px */
  background-color: #f7ce46;
  box-shadow: 2px 2px 15px #f7ce46;
  border-radius: 1px;
}

.ball {
  width: 5vw; /* 100px */
  height: 5vw; /* 100px */
  border-radius: 20vw;  /* 50% */       /* Turns a square into a circle   */
  background-color: #e39b36;/*#116466; */  /* Sets color to Orange           */
  animation: bounce 0.6s; /* cubic-bezier(.5,0.05,1,.5); */
  animation-direction: alternate;
  animation-iteration-count: infinite;
  // box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  box-shadow: 2px 2px 15px #e39b36;
}

@keyframes bounce {
  from {transform: translate3d(0, -29.7vh, 0)} /*-320px */
  to   {transform: translate3d(0, 29.7vh, 0)} /*vh is better */
}

.ball_animation_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  // border: 1px solid green;
  // margin-right: 100px; 
  animation: slide 20s;
  animation-iteration-count: infinite;
  // height: 1080px; 
  min-height: 100vh; /*1080px*/ /*will increase on bigger screen, flex at play */ 
  // animation-timing-function: linear;
  // animation-duration: 13s;
}

@keyframes slide {
  0% { transform: translate(-100vw, 0px) rotate(0deg); }
  25% { transform: translate(0px, 0px) rotate(0deg); }
  50% {transform: translate(-79vw, 0px) rotate(0deg);}
  // 75% {transform: translate(10vw, 0px) rotate(0deg);}
  80% { transform: translate(0px, 0px) rotate(0deg); }
  90% { transform: translate(0px, 0px) rotate(0deg); }
  100% { transform: translate(0px, 0px) rotate(0deg); }
}


//Skills 

.skills_wrapper {
  // border: 2px solid green; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw; /*1600px; */
}

.my_skills {
  // border: 1px solid yellow;
  width: 50%; /* 1100px */ /* 800px */
  margin-left: 5%;
  // height: 650px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
}
// box

#wrapper {
  // position: absolute;
  // left: 50%;
  // top: 50%;
  perspective: 50vh; /* 1500px */
  -webkit-perspective: 50vh;
}

.box-area {
  position: relative;
  transform-style: preserve-3d;
  animation-name: rotate;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-transform-style: preserve-3d;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

// .box div {
//   border: 2px solid green; 
// }


@keyframes rotate {
  0% {
    transform: rotate3d(0, 0, 0, 0);
  } 
  50% {
    transform: rotate3d(0, 0.5, 0, 180deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 0, 0);
  } 
  50% {
    -webkit-transform: rotate3d(0, 0.5, 0, 180deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 360deg);
  }
}


// #wrapper {
//   border: 2px solid yellow;
//   width: 300px;
//   height: 300px;
// }

.box {
  margin-left: 10px; //this gives it the special twist /* 75px */
  position: absolute;
  width: 28vh; /* 302.5px; */
  height: 28vh;
  border: 2px solid #e39b36;/*#262626;*/
  box-shadow: 2px 2px 15px #e39b36;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2vw; /* 55px */
  // flex-wrap: wrap;
  // align-items: flex-start;
}

.box h6 {
  color: #e6ffff;
  // font-size: 2vw;
  font-size: 68%;
}

.icons_container {
  // border: 3px solid orange;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.fab, .fas {
  // border: 1px solid black;
  font-size: 4vw; /* 75 px */
  width: 4vw;
  height: 4vw;
  text-align: center;
}

.box img {
  // border: 1px solid black;
  width: 30%; /*4vw*/
  height: 30%; /*4vw*/
  text-align: center;
}

#box-front {
  transform: translateX(-13.999999475vh) translateY(-13.999999475vh) translateZ(13.999999475vh);
  -webkit-transform: translateX(-13.999999475vh) translateY(-13.999999475vh) translateZ(13.999999475vh);
  // background: rgba(0, 0, 0, 0.05);
  background-color: #ffe64d40;
}

#box-back {
  transform: translateX(-13.999999475vh) translateY(-13.999999475vh) translateZ(-13.999999475vh);
  -webkit-transform: translateX(-13.999999475vh) translateY(-13.999999475vh) translateZ(-13.999999475vh);
  // background: rgba(0, 0, 0, 0.05);
  background-color: #ffe64d40;
}

#box-back img, #box-back i, #box-left img, #box-left i {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

#box-right {
  transform: translateY(-13.999999475vh) rotateY(90deg);
  -webkit-transform: translateY(-13.999999475vh) rotateY(90deg);
  background-color: #ffe64d40;
}

#box-left {
  transform: translateY(-13.999999475vh) translateX(-28vh) rotateY(90deg);
  -webkit-transform: translateY(-13.999999475vh) translateX(-28vh) rotateY(90deg);
  background-color: #ffe64d40;
}

#box-top {
  transform: translateX(-13.999999475vh) translateY(-28vh) rotateX(90deg);
  -webkit-transform: translateX(-13.999999475vh) translateY(-28vh) rotateX(90deg);
  background-color: #ffe64d40;
}

#box-bottom {
  transform: translateX(-13.999999475vh) rotateX(90deg);
  -webkit-transform: translateX(-13.999999475vh) rotateX(90deg);
  background-color: #ffe64d40;
}

.box-area {
  transform: rotateX(90deg) rotateY(90deg);
  -webkit-transform: rotateX(90deg) rotateY(90deg);
}

#box-left h6 {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

#box-back h6 {
  transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
}

//about me

.aboutme_screen_wrapper {
  height: 100vh; 
  width: 100vw; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.aboutme_wrapper {
  // border: 1px solid red;
  width: 60%;
  // padding-left: 200px;
  // margin: 200px auto; 
  margin-left: 10%;
  position: relative;
}


//Contact

.contact_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.contact_icons_wrapper {
  // border: 1px solid red;
  // height: 700px; 
  width: 80%; /* 1600px */
  // word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10%;
}

.contact_icons_wrapper a {
  text-decoration: none;
  color: #d1e8e2;
}

.contact_icons_wrapper span {
  font-size: 2.5vw; /* 50px */
}

.fa-github-square::before, .fa-linkedin::before, .fa-envelope-square::before{
  margin-right: 15px; 
  color: #e6ffff;
}

//projects

.projects_wrapper {
  height: 100vh;
  width: 100vw; 
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.all_projects {
  // margin: 0 auto;
  // border: 1px solid green;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // height: 50vh; /* 100vh */
  width: 85%; /*50vw; */ /* 100vw */
  // padding: 15px 0px;
  padding-left: 20px; 
  flex-wrap: wrap;
}

.all_projects h3 {
  font-size: 0.975vw;
}

.all_projects div {
  // border: 1px solid red;
  border-radius: 15px;
  margin-left: 3px; 
  // box-shadow: 0 0 30px 2px #00BFFF; 
  // margin: 25px 5px;
  // background-color: rgba(0, 0, 0, 0.9);
}

.all_projects div:hover {
  color: #00BFFF;
}

.caishen_two {
    animation: from_left;
    animation-duration: 1.8s;
    animation-iteration-count: 1;
    -webkit-animation: from_left;
    -webkit-animation-duration: 1.8s;
    -webkit-animation-iteration-count: 1;
    background-image: url('../../src/media/caishen2_home.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 15vw; 
    background-position: top;
    width: 14.5vw; 
    height: 28vw; 
    color: #e6ffff;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 5px;
}

.project_four {
  // border: 1px solid green;
  animation: from_left;
  animation-duration: 1.8s;
  animation-iteration-count: 1;
  -webkit-animation: from_left;
  -webkit-animation-duration: 1.8s;
  -webkit-animation-iteration-count: 1;
  background-image: url('../../src/media/caishen.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15vw; /* 300px */
  background-position: top;
  width: 14.5vw; /* 300px */
  height: 28vw; /* 570px */
  // align-self: flex-start;
  color: #e6ffff;
  // align-self: flex-start;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 5px;
}

// .project_four {
//   animation: click 0.5s; 
//   animation-iteration-count: infinite;
// }

.project_three {
  // border: 1px solid green;
  animation: from_top 1.5675s;
  animation-iteration-count: 1;
  -webkit-animation: from_top 1.5675s;
  -webkit-animation-iteration-count: 1;
  background-image: url('../../src/media/quiz.png');
  background-position: center;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 26.5vw; /* cover */ /* 520px */
  width: 26vw; /* 520 px */
  height: 21vw; /* 470px */
  // align-self: flex-end;
  color: #e6ffff;
  // align-self: flex-end;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 5px;
}

.project_two {
  // border: 1px solid green;
  animation: from_right 1.5s;
  animation-iteration-count: 1;
  -webkit-animation: from_right 1.5s;
  -webkit-animation-iteration-count: 1;
  background-image: url('../../src/media/pokezon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 25vw; /* 500px */
  width: 25vw; /* 510px */
  height: 15.5vw; /* 340px */
  // align-self: flex-start;
  color: #e6ffff;
  // align-self: flex-start;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 5px;
}

.project_one {
  // border: 1px solid green;
  animation: from_below 1.5675s; /* cubic-bezier(.5,0.05,1,.5); */
  // animation-direction: alternate;
  animation-iteration-count: 1;
  -webkit-animation: from_below 1.5675s; /* cubic-bezier(.5,0.05,1,.5); */
  -webkit-animation-iteration-count: 1;
  background-image: url('../../src/media/homerman.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14.5vw; /* 300px */
  background-position: top;
  width: 13.8vw; /* 300px */
  height: 18.5vw;; /* 410 px */
  color: #e6ffff;
  // align-self: flex-end;
  // align-self: flex-end;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 5px;
}


@keyframes click_updown {
  0% { -webkit-transform: translate(0px, 20px), rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

@keyframes from_left {
  0% { -webkit-transform: translate(-8000px, 0px) rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

@keyframes from_top {
  0% { -webkit-transform: translate(0px, -8000px) rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

@keyframes from_right {
  0% { -webkit-transform: translate(8000px, 0px) rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

@keyframes from_below {
  0% { -webkit-transform: translate(0px, 8000px) rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

//

@-webkit-keyframes click_updown {
  0% { -webkit-transform: translate(0px, 20px), rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

@-webkit-keyframes from_left {
  0% { -webkit-transform: translate(-8000px, 0px) rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

@-webkit-keyframes from_top {
  0% { -webkit-transform: translate(0px, -8000px) rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

@-webkit-keyframes from_right {
  0% { -webkit-transform: translate(8000px, 0px) rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

@-webkit-keyframes from_below {
  0% { -webkit-transform: translate(0px, 8000px) rotate(0deg); }
  100% { -webkit-transform: translate(0px, 0px), rotate(0deg); }
}

// Projects

//caishen

.project_wrapper {
  // border: 1px solid purple;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw; 
}

.caishen_wrapper {
  // border: 1px solid red;
  height: 100vh;
  width: 100vw; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.caishen_details {
  // border: 1px solid green;
  width: 50%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1vw;; /* 17px */
}

.open_application {
  color: #00BFFF;
  font-size: 2vh; /* 30px */ /* 2.7 */
}

.view_code {
  font-size: 2vw;
  display: inline;
  margin-left: 4px; 
}

.please_contact {
  color: green;
  font-size: 1vw;
}

.fa-external-link-alt {
  font-size: 2.7vh; 
  // border: 1px solid green;
  display: inline;
  text-align: center;
  margin-left: 5px; 
}

.side_project_picture {
  // border: 1px solid orange;
  width: 50%;
  // height: 100%;
  color: orange;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.side_project_picture_caishen_2 {
  // border: 1px solid orange;
  width: 50%;
  // height: 100%;
  color: orange;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.side_project_picture_pokezon {
  // border: 1px solid orange;
  width: 50%;
  height: 95%;
  color: orange;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.video_caishen {
  width: 20vw; /* 400 px */
  // display: inline;
  // border: 1px solid grey;
}

.video_homerman {
  width: 40vw; 
}

.video_quiz {
  width: 40vw;
}

.video_pokezon {
  width: 40vw;
}

.video_caishen2 {
  width: 40vw;
  border-radius: 10px;
}

.coming_soon {
  color: orange;
  display: inline;
  font-size: 0.8vw; /* 18px */ /* 0.9 */
}

.project_title {
  font-size: 2.75vw; /* 60px */ /*3.15 */ /* 2.85 */
}


@media screen and (max-width: 600px) {

  .please_contact {
    font-size: 6px;
  }

  .coming_soon {
    font-size: 6px;
  }

  .all_projects h3 {
    font-size: 5px; 
  }

  .project_one {
    width: 100px; 
    // border: 0.5px solid red;
  }

  .nav_wrapper {
    margin-right: 10px; 
  }

  .nav_wrapper h4 {
    font-size: 9px;
    line-height: 10px;
  }

  .nav_wrapper span {
    font-size: 7px;
  }

  .my_skills {
    // border: 1px solid yellow;
    width: 36%; 
    margin-bottom: 70px;
  }

  #wrapper {
    margin-bottom: 70px;
  }

  .tag {
    font-size: 5px;
  }

  .topic_title {
    font-size: 21px;
  }

  .topic_title_name {
    font-size: 21px;
    white-space: normal;
    animation: none;
  }

  .para {
    font-size: 6px;
  }

  .box {
    width: 14vh;
    height: 14vh; 
  }

  #box-front {
    transform: translateX(-6.9999997375vh) translateY(-6.9999997375vh) translateZ(6.9999997375vh);
    -webkit-transform: translateX(-6.9999997375vh) translateY(-6.9999997375vh) translateZ(6.9999997375vh);
    // background: rgba(0, 0, 0, 0.05);
    background-color: #ffe64d40;
  }
  
  #box-back {
    transform: translateX(-6.9999997375vh) translateY(-6.9999997375vh) translateZ(-6.9999997375vh);
    -webkit-transform: translateX(-6.9999997375vh) translateY(-6.9999997375vh) translateZ(-6.9999997375vh);
    // background: rgba(0, 0, 0, 0.05);
    background-color: #ffe64d40;
  }
  
  #box-back img, #box-back i, #box-left img, #box-left i {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
  }
  
  #box-right {
    transform: translateY(-6.9999997375vh) rotateY(90deg);
    -webkit-transform: translateY(-6.9999997375vh) rotateY(90deg);
    background-color: #ffe64d40;
  }
  
  #box-left {
    transform: translateY(-6.9999997375vh) translateX(-28vh) rotateY(90deg);
    -webkit-transform: translateY(-6.9999997375vh) translateX(-14vh) rotateY(90deg);
    background-color: #ffe64d40;
  }
  
  #box-top {
    transform: translateX(-6.9999997375vh) translateY(-28vh) rotateX(90deg);
    -webkit-transform: translateX(-6.9999997375vh) translateY(-14vh) rotateX(90deg);
    background-color: #ffe64d40;
  }
  
  #box-bottom {
    transform: translateX(-6.9999997375vh) rotateX(90deg);
    -webkit-transform: translateX(-6.9999997375vh) rotateX(90deg);
    background-color: #ffe64d40;
  }
}